export default {
  ceecee: {
    title: "Cosmic Sisters",
    description:
      "The Cosmic Sisters are a collaboration with AI. They serve as a love letter to artist Cee Cee’s connection with the Polygon Community. Using photography archives, poetry, and many iterations, Cee Cee has crafted a universe within the budding petals of her favorite flower.<br/><br/>Initially created as a thank-you gift, the Sisters have been showcased at several gallery openings in the Metaverse and IRL. Taking on a life of their own, the remaining unminted Sisters have an opportunity to shine thanks to Magic Eden and Polygon. These ladies represent Cee Cee’s journey of self-discovery, surviving our collective trauma and the beautifully infinite world that lives within us.",
    author: "Cee Cee",
    hideSoldOutTokens: false,
    externalUrl: "https://hiceecee.my.canva.site/",
  },
  celestialBody: {
    title: "The Rise and the Fool",
    description:
      "A two piece exploration of the potential for wonderment and destructive dissolution we hold in our collective hands. Braille stars are rendered flat, a representation of the ideas we project yet fail to connect with.",
    author: "Celestial Body",
    hideSoldOutTokens: false,
    externalUrl: "https://www.celestialbody.space/",
  },
  laurenceAntony: {
    title: "Heat of the moment",
    description:
      "“Heat of the moment”, is a 1/1 piece produced exclusively for <strong>Magic Eden x Art Basel @Scope Art Show.</strong> The scene in which a roller skater makes her way through violent protests reflects on the last few years of social instability. It highlights the importance of creativity and subversive thought in the midst of chaos. Several photos of protests in France, Venezuela, Hong Kong were referenced to collage a cohesive composition. Inspired by romantic style painting, the piece is rendered on three different digital creation apps to replicate the aesthetic of oil painting.",
    author: "Laurence Antony",
    hideSoldOutTokens: false,
    externalUrl: "https://twitter.com/laurence_antony",
  },
  hofaDao: {
    title:
      "The Gardener of the Kairaku-en Garden I Kōrakuen no niwashi I 後楽園の庭師",
    description:
      "Kairaku-en can be translated as “a garden to enjoy with people.” Once a private garden, its opening to the general public helped sparked a movement towards public parks. The Gardener in this artwork prepares all the lovely and colorful flowers during the dark and cold winter time to be ready to show off in the Garden in the upcoming spring. Both worlds of emotions can be seen in the artwork, the pale and kind of sad Gardener that works on his flowers and the colorful world of the flowers living despite cold weather and winter outside in the glasshouse. This artwork should make the betrayer happy, happy for what's upcoming even if the wintertime is cold and grey but there is always a reason to be happy and look forward.",
    author: "Richard Nadler",
    hideSoldOutTokens: false,
    externalUrl: "https://twitter.com/RichardNadler1",
  },
  innaModjaMcsk: {
    title: "The Knights",
    description:
      "Inna Modja is a Malian-French Visual Artist & Musician. Her photography whether in a traditional studio, or a mobile one set-up in the middle of nature or the street, focuses on capturing vibrant portraits of every day heroes. She's a strong global figure for Social & Climate Justice, co-founder of Code Green & Head of philanthropy of World of Women.<br/><br/>MCSK is an Italian-Croatian artist, architect and photographer who began to create art as a child during the war in former Yugoslavia. Inspired by classical and renaissance art during formative years in Rome, MCSK’s work now intimately explores the unknown, the secret of our existence and the incredible power of love.",
    author: "Inna Modja + MCSK",
    hideSoldOutTokens: false,
    externalUrl: "https://linktr.ee/innamodja_mcsk",
  },
};
