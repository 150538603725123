// testnet
// export default {
//   ceecee: {
//     contractId: "a11ef3aa-fa1a-41c7-a547-008a38d754ab",
//     enableLogging: true,
//   },
//   celestialBody: {
//     contractId: "b2ef7e0e-48ad-40ba-b492-f61a23494eeb",
//     enableLogging: true,
//   },
//   laurenceAntony: {
//     contractId: "833419f7-75d0-4a41-8e92-543a344a1528",
//     enableLogging: true,
//   },
//   hofaDao: {
//     contractId: "92940e19-ec15-4549-aac4-bd036ed6e677",
//     enableLogging: true,
//   },
//   innaModjaMcsk: {
//     contractId: "1767c1c0-8a0a-49d6-8add-440a4f719ef5",
//     enableLogging: true,
//   },
// };
// mainnet
export default {
  ceecee: {
    contractId: "8be565e4-7621-4b81-a337-b0d816e2b042",
    enableLogging: true,
  },
  celestialBody: {
    contractId: "c8ab0821-fd32-493a-b3b3-dfdfb61f85c0",
    enableLogging: true,
  },
  laurenceAntony: {
    contractId: "da1c801c-1cb7-4098-8947-6adfdc2fe12a",
    enableLogging: true,
  },
  hofaDao: {
    contractId: "4f962a2f-8bb8-48cc-bb82-6815fc629276",
    enableLogging: true,
  },
  innaModjaMcsk: {
    contractId: "278c4ead-7b65-4909-87e7-c31ab4d52784",
    enableLogging: true,
  },
};
