import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import { ContractProvider } from "./context/ContractContext";
import { ThemeProvider } from "./context/ThemeContext";
import StoreFront from "./pages/StoreFront";
import ConfigurationImporter from "./utils/ConfigurationImporter";

const ceeceeConfig = new ConfigurationImporter("ceecee");
const celestialBodyConfig = new ConfigurationImporter("celestialBody");
const laurenceAntonyConfig = new ConfigurationImporter("laurenceAntony");
const hofaDaoConfig = new ConfigurationImporter("hofaDao");
const innaModjaMcskConfig = new ConfigurationImporter("innaModjaMcsk");

const withConfig = (
  config: ConfigurationImporter,
  children?: React.ReactNode
) => (
  <ThemeProvider configurationImporter={config}>
    <AppProvider>
      <ContractProvider configurationImporter={config}>
        {children ? children : <StoreFront configurationImporter={config} />}
      </ContractProvider>
    </AppProvider>
  </ThemeProvider>
);

const Routes = () => {
  return (
    <Switch>
      <Route index element={withConfig(ceeceeConfig)} />
      <Route path="/cee-cee" element={withConfig(ceeceeConfig)} />
      <Route
        path="/celestial-body"
        element={withConfig(celestialBodyConfig)}
      />
      <Route
        path="/laurence-antony"
        element={withConfig(laurenceAntonyConfig)}
      />
      <Route path="/hofa-dao" element={withConfig(hofaDaoConfig)} />
      <Route
        path="/inna-modja-mcsk"
        element={withConfig(innaModjaMcskConfig)}
      />
    </Switch>
  );
};

const App = () => {
  return (
    <Router>
      <Routes />
    </Router>
  );
};

export default App;
