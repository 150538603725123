export default {
  ceecee: {
    images: {
      background: require("../assets/bg_home.png"),
      logo: require("../assets/logo.png"),
    },
    colors: {
      primary: "228, 37, 117",
      success: "22, 230, 181",
      error: "231, 102, 85",
    },
  },
  celestialBody: {
    images: {
      background: require("../assets/bg_home.png"),
      logo: require("../assets/logo.png"),
    },
    colors: {
      primary: "228, 37, 117",
      success: "22, 230, 181",
      error: "231, 102, 85",
    },
  },
  laurenceAntony: {
    images: {
      background: require("../assets/bg_home.png"),
      logo: require("../assets/logo.png"),
    },
    colors: {
      primary: "228, 37, 117",
      success: "22, 230, 181",
      error: "231, 102, 85",
    },
  },
  hofaDao: {
    images: {
      background: require("../assets/bg_home.png"),
      logo: require("../assets/logo.png"),
    },
    colors: {
      primary: "228, 37, 117",
      success: "22, 230, 181",
      error: "231, 102, 85",
    },
  },
  innaModjaMcsk: {
    images: {
      background: require("../assets/bg_home.png"),
      logo: require("../assets/logo.png"),
    },
    colors: {
      primary: "228, 37, 117",
      success: "22, 230, 181",
      error: "231, 102, 85",
    },
  },
};
